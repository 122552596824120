import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { getText, getHTML } from '../../../utils/cms_copy_provider';
import getQueryParam from '../../../utils/getQueryParam';
import isApp from '../../../utils/isApp';
import sendTicketEntryMetrics from '../../../utils/sendTicketEntryMetrics';

export const EntryForm = ({ promotion_id, promotion, appUrl, createfunc, ticket, entry, actions }) => {
    const formUrl = appUrl + '/entry.php';

    const qp_ticket = typeof getQueryParam('ticket') !== 'undefined' ? getQueryParam('ticket').match(/...../g) : [];

    const initialState = {
        ticket1_1_1: qp_ticket[0] ? qp_ticket[0] : '',
        ticket1_1_2: qp_ticket[1] ? qp_ticket[1] : '',
        ticket1_1_3: qp_ticket[2] ? qp_ticket[2] : '',
        ticket1_1_4: qp_ticket[3] ? qp_ticket[3] : '',
        ticket1_1_5: qp_ticket[4] ? qp_ticket[4] : '',

        entry_method: 'manual',
        form_submitted: '1',
        'entry-submit': 'SUBMIT+ENTRY',
        promotion: promotion,
    };

    const [formState, setFormState] = useState(initialState);

    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        clearIfSuccess();
    }, [ticket]);

    // fire the submission
    const handleSubmit = async event => {
        setDisabled(true);
        event.preventDefault();

        let wasScanned = formState.entry_method === 'scan' ? true : false;
        sendTicketEntryMetrics(isApp(), wasScanned, ticket.success);

        await createfunc(formState);

        setDisabled(false);
    };

    // if the form field changes, we will change the value in the store and
    // also check if the next field must be focused
    const handleChange = function(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormState({ ...formState, [name]: value.toUpperCase() });

        autotab(target, target.dataset.next);
    };

    // if the form submit was a success, we clear the form
    const clearIfSuccess = async () => {
        if (ticket.success) {
            // UNCOMMENT FOR GAME MODAL on URL
            //await actions.gameActions.getGame(entry.last_entry);

            setFormState(initialState);
            actions.drawTicketActions.reset();
            navigate('/post_claim', {
                state: { returnURL: '/enter_draw' },
            });
        }
    };

    // rehash of old autotab functionality from PA
    const autotab = (current, nex) => {
        var next = document.getElementById(nex);

        if (!!nex && !!current && current.getAttribute && current.value.length === parseInt(current.getAttribute('maxlength'))) {
            next.focus();
        }
    };

    return (
        <div id='form-target' className='entry-form draw-form'>
            <form
                name='ticket_entry'
                id='ticket_entry'
                action={formUrl}
                disabled={disabled}
                method='post'
                encType='application/x-www-form-urlencoded'
                onSubmit={handleSubmit}>
                <div className='row entry-fields'>
                    <div className='col'>
                        <div className='form-row mb-2'>
                            <div className='col-4 col-lg-3 offset-lg-2'>
                                <input
                                    type='text'
                                    name='ticket1_1_1'
                                    id='ticket1_1_1'
                                    data-next='ticket1_1_2'
                                    value={formState.ticket1_1_1}
                                    onChange={handleChange}
                                    size='5'
                                    maxLength='5'
                                    className='form-control entry-input'
                                />
                            </div>
                            <div className='col-4 col-lg-3'>
                                <input
                                    type='text'
                                    name='ticket1_1_2'
                                    id='ticket1_1_2'
                                    data-next='ticket1_1_3'
                                    value={formState.ticket1_1_2}
                                    onChange={handleChange}
                                    size='5'
                                    maxLength='5'
                                    className='form-control entry-input'
                                />
                            </div>
                            <div className='col-4 col-lg-3'>
                                <input
                                    type='text'
                                    name='ticket1_1_3'
                                    id='ticket1_1_3'
                                    data-next='ticket1_1_4'
                                    value={formState.ticket1_1_3}
                                    onChange={handleChange}
                                    size='5'
                                    maxLength='5'
                                    className='form-control entry-input'
                                />
                            </div>
                            <div className='offset-2 offset-lg-3 col-4 col-lg-3'>
                                <input
                                    type='text'
                                    name='ticket1_1_4'
                                    id='ticket1_1_4'
                                    data-next='ticket1_1_5'
                                    value={formState.ticket1_1_4}
                                    onChange={handleChange}
                                    size='5'
                                    maxLength='5'
                                    className='form-control entry-input'
                                />
                            </div>
                            <div className='col-4 col-lg-3'>
                                <input
                                    type='text'
                                    name='ticket1_1_5'
                                    id='ticket1_1_5'
                                    value={formState.ticket1_1_5}
                                    onChange={handleChange}
                                    size='5'
                                    maxLength='5'
                                    className='form-control entry-input'
                                />
                            </div>
                        </div>
                        <p>{getHTML('enter_draw_vowels_note')}</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='entry-submit col-md-5 offset-md-3'>
                        <input id='entry-submit' type='submit' name='submit' value={getText('enter_draw_btn_enterticket')} className='entry-submit' />
                    </div>
                </div>
                <input type='hidden' id='entry_method' name='entry_method' value={formState.entry_method} />
                <input type='hidden' id='form_submitted' name="form_submitted: '1'" value={formState.form_submitted} />
            </form>
        </div>
    );
};

export default EntryForm;
