import urlObjectList from '../global/cms/header_footer.json';

export const getUrl = title => {
    let mainArray = []

    // recursive method to push items onto mainArray
    const iterate = (list, callback) => {
        list.map((item) => {
            mainArray.push({
                name: item.name,
                get_url: item.get_url
            })

            callback(item.children, callback)
        })
    }

    // call recursive method recursively
    iterate(urlObjectList, iterate);

    const matchTitle = mainArray.filter(field => {
        return field.name == title;
    });

    if (matchTitle.length) {
        const textBlock = matchTitle[0].get_url;

        return textBlock;
    } else {
        return null;
    }
};
