import React from 'react';
import isBrowser from '../../utils/isBrowser';
import getQueryParam from '../../utils/getQueryParam';
import './backStyle.scss';

const BackButton = () => {
    const onBack = e => {
        e.preventDefault();
        if (isBrowser()) {
            window.location = getQueryParam('BackCTA');
        }
    };

    if (getQueryParam('BackCTA')) {
        return (
            <div className="back-button">
                <a href="#" onClick={onBack}>
                    &lt;&lt; Back
                </a>
            </div>
        );
    } else {
        return null;
    }
};

export default BackButton;
