module.exports = {
    getValue: (value) => {
        const testValues = {
            // if "enabled" is ever true when committing, shame!
            enabled: false,
            // props: true || false || null (keep original value)
            loggedIn: false,
        };

        return testValues.enabled && testValues[value]
    }
};