import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import promotion from '../../../promotion_config';
import isBrowser from '../../utils/isBrowser';

import './loading_style.scss';
import test_harness from '../../../test_harness';

const UserLockout = ({ children, loading, user, config, actions }) => {
    const [hasMounted, setHasMounted] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        setHasMounted(true);
    }, []);

    useEffect(() => {
        let myLocation = isBrowser() ? location.protocol + '//' + location.host + location.pathname : '';

        // remove any trailing slashes
        myLocation = myLocation.replace(/\/$/, '');

        const defaultLoginUrl = promotion.getLoginLink(myLocation, myLocation);

        if (!hasMounted || loading.actions['getPlayerData'] || loading.actions['getConfig'] || loading.actions['login']) {
            setIsLoggedIn(false);
        } else {
            if (user.loggedIn || test_harness.getValue('loggedIn')) {
                setIsLoggedIn(true);
            } else {
                if (isBrowser()) {
                    const loginUrl = config.config.login_host_uri
                        ? `${config.config.login_host_uri}?PtaUrl=${myLocation}&Redirect=${myLocation}`
                        : defaultLoginUrl;
                    window.location.href = loginUrl;
                }
                return null;
            }
        }
    }, [user, loading]);

    if (!isLoggedIn) {
        return (
            <div className='loading-wrapper'>
                <div className='loading-overlay'>
                    <div className='loading-icon ellipses'></div>
                </div>
            </div>
        );
    } else {
        if (isLoggedIn) {
            return children;
        } else {
            return null;
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserLockout);
