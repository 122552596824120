import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { getText } from '../../utils/cms_copy_provider';
import isBrowser from '../../utils/isBrowser';
import PageLoading from '../widgets/page_loading';

export const Scanner = ({ scratchTicket, actions }) => {
    const [requestMade, setRequestMade] = useState(false);

    useEffect(() => {
        actions.revealTokenActions.getRevealToken();
    }, []);

    useEffect(() => {
        clearIfSuccess();
    }, [scratchTicket]);

    const scanCallback = function(infoObject) {
        if (infoObject) {
            console.log('🎁 infoObject:', infoObject);

            const format = infoObject.format.toUpperCase();
            const webCodeBarCode = infoObject.result;

            if (format !== 'UNDEFINED' && format !== 'USER_CANCELLED' && infoObject.result !== null && infoObject.result !== '') {
                let sending = {
                    ticket1_1_1: webCodeBarCode,
                    entry_method: 'scan',
                };
                setRequestMade(true);
                actions.scratchTicketActions.enterScratchTicket(sending);
            }
        }
    };

    // if the form submit was a success, we clear the form
    const clearIfSuccess = async () => {
        if (scratchTicket.success) {
            navigate('/post_claim', {
                state: { returnURL: '/enter' },
            });
        } else {
            setRequestMade(false);
        }
    };

    const doScan = () => {
        if (isBrowser() && typeof window.MdiNativeShell !== 'undefined' && !requestMade) {
            console.log('🟢 OK: scanBarcode', requestMade);
            window.MdiNativeShell.scanBarcode(scanCallback.bind(this));
        } else {
            console.log('🔴 Failed: scanBarcode', requestMade);
        }
    };

    return (
        <>
            <a href='javascript:void(0);' onClick={doScan} className={`theme-btn scan-btn ${requestMade ? ' disabled' : ''}`}>
                {getText('ticket_checker_scan_btn')}
            </a>
            <PageLoading loadedAction='enterScratchTicket' hasIcon='true' />
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Scanner);
